import { faFigma as faFigmaBrands } from '@fortawesome/free-brands-svg-icons'
import {
    faArchive as faArchiveDuotone,
    faArrowLeftFromLine as faArrowLeftFromLineDuotone,
    faArrowRightToBracket as faArrowRightToBracketDuotone,
    faBell as faBellDuotone,
    faBoltLightning as faBoltLightningDuotone,
    faBrowser as faBrowserDuotone,
    faCalendarDays as faCalendarDaysDuotone,
    faCreditCard as faCreditCardDuotone,
    faFileInvoice as faFileInvoiceDuotone,
    faGauge as faGaugeDuotone,
    faHouse as faHouseDuotone,
    faHouseFlag as faHouseFlagDuotone,
    faKey as faKeyDuotone,
    faLock as faLockDuotone,
    faPaperPlane as faPaperPlaneDuotone,
    faSparkles as faSparklesDuotone,
    faSpinnerThird as faSpinnerThirdDuotone,
    faTrashCan as faTrashCanDuotone,
    faUserCheck as faUserCheckDuotone,
    faUser as faUserDuotone,
    faUsers as faUsersDuotone,
} from '@fortawesome/pro-duotone-svg-icons'
import {
    faAlignCenter,
    faAlignJustify,
    faAlignLeft,
    faAlignRight,
    faArchive,
    faArrowLeftLong,
    faArrowRightLong,
    faArrowUpRightFromSquare,
    faAt,
    faBackwardFast,
    faBadgeCheck,
    faBars,
    faBell,
    faBlockQuote,
    faBold,
    faBolt,
    faBrowser,
    faCalendarCheck,
    faCaretDown,
    faCaretLeft,
    faCaretRight,
    faCaretUp,
    faCheck,
    faCheckCircle,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle,
    faCircleExclamation,
    faCircleInfo,
    faCircleSmall,
    faCode,
    faCommentCaptions,
    faComments,
    faCopy,
    faDesktop,
    faDisplay,
    faDoNotEnter,
    faEdit,
    faEllipsis,
    faEllipsisV,
    faExpandWide,
    faFile,
    faFiles,
    faFilter,
    faFolders,
    faForwardFast,
    faGauge,
    faGear,
    faGlobe,
    faGrid,
    faGripDots,
    faHorizontalRule,
    faHyphen,
    faImages,
    faInfoCircle,
    faItalic,
    faKey,
    faLaptop,
    faLightbulbOn,
    faLink,
    faList,
    faListNumeric,
    faListOl,
    faListUl,
    faLock,
    faMagnifyingGlass,
    faMessages,
    faMinus,
    faMobile,
    faOctagonExclamation,
    faPaintBrush,
    faPaperPlane,
    faPencil,
    faPlug,
    faPlus,
    faPlusCircle,
    faRectangleWide,
    faRefresh,
    faReply,
    faRotateRight,
    faSealQuestion,
    faShare,
    faSidebarFlip,
    faSliders,
    faSparkles,
    faSpinnerThird,
    faSquareKanban,
    faSquareMinus,
    faStar,
    faStrikethrough,
    faTablet,
    faTag,
    faThumbsUp,
    faTimesCircle,
    faTrashCan,
    faTriangleExclamation,
    faUnderline,
    faUpload,
    faUpRightAndDownLeftFromCenter,
    faUser,
    faUserCheck,
    faUserGroup,
    faUserPlus,
    faUsers,
    faVideo,
    faWindowRestore,
} from '@fortawesome/pro-solid-svg-icons'

export const Icons = {
    faAlignCenter,
    faAlignJustify,
    faAlignLeft,
    faAlignRight,
    faArrowLeftLong,
    faAt,
    faBadgeCheck,
    faBars,
    faBlockQuote,
    faBold,
    faBolt,
    faCaretLeft,
    faCaretRight,
    faChevronUp,
    faChevronDown,
    faChevronRight,
    faChevronLeft,
    faCode,
    faDesktop,
    faEllipsisV,
    faExpandWide,
    faFilter,
    faGear,
    faGrid,
    faItalic,
    faLink,
    faList,
    faListNumeric,
    faListOl,
    faListUl,
    faMobile,
    faPaintBrush,
    faPlus,
    faPlusCircle,
    faShare,
    faSparkles,
    faSpinnerThird,
    faStrikethrough,
    faTablet,
    faThumbsUp,
    faUnderline,
    faUpload,
    faUserGroup,
    faBackwardFast,
    faForwardFast,
    faSealQuestion,
    faArrowUpRightFromSquare,
    faInfoCircle,
    faPlug,
    faEdit,
    faReply,
    faMessages,
    faHorizontalRule,
    faEllipsis,
    faCopy,
    faPencil,
    faRefresh,
    faTimesCircle,
    faCheckCircle,
    faFolders,
    faGlobe,
    faCaretDown,
    faCaretUp,
    faCheck,
    faBell,
    faUserPlus,
    faTag,
    faCommentCaptions,
    faArrowRightLong,
    faImages,
    faVideo,
    faSidebarFlip,
    faSliders,
    faTriangleExclamation,
    faWindowRestore,
    faArchive,
    faStar,
    faMagnifyingGlass,
    faUserCheck,
    faUsers,
    faPaperPlane,
    faFile,
    faFiles,
    faKey,
    faCircle,
    faCircleSmall,
    faCalendarCheck,
    faHouseDuotone,
    faUserCheckDuotone,
    faUsersDuotone,
    faPaperPlaneDuotone,
    faUserDuotone,
    faLockDuotone,
    faBrowserDuotone,
    faTrashCanDuotone,
    faFileInvoiceDuotone,
    faKeyDuotone,
    faUser,
    faBrowser,
    faCircleInfo,
    faGripDots,
    faBoltLightningDuotone,
    faCreditCardDuotone,
    faArchiveDuotone,
    faBellDuotone,
    faTrashCan,
    faCalendarDaysDuotone,
    faSparklesDuotone,
    faRectangleWide,
    faDisplay,
    faArrowRightToBracketDuotone,
    faArrowLeftFromLineDuotone,
    faLaptop,
    faSpinnerThirdDuotone,
    faOctagonExclamation,
    faDoNotEnter,
    faLock,
    faHouseFlagDuotone,
    faSquareMinus,
    faFigmaBrands,
    faMinus,
    faHyphen,
    faUpRightAndDownLeftFromCenter,
    faLightbulbOn,
    faSquareKanban,
    faComments,
    faCircleExclamation,
    faRotateRight,
    faGauge,
    faGaugeDuotone,
}
